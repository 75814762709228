import profile from "@/router/admin/profile";
import produce from "@/router/admin/crops";
import producers from "@/router/admin/producers";
import messaging from "@/router/admin/messaging";

const childrenRoutes = [
  {
    path: "/admin-dashboard",
    name: "Admin Dash",
    component: () => {
      return import("@/components/admin/dashboard/Index");
    },
    meta: {
      title: "Lima | Dashboard",
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/buyers",
    name: "Buyers",
    component: () => import("@/components/admin/users/Buyers"),
    meta: {
      title: "Lima | Buyers",
      requiresAuth: false,
      isAdmin: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("@/components/admin/users/Users"),
    meta: {
      title: "Lima | Users",
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "/users/system-admin",
        name: "System Admin",
        component: () => import("@/components/admin/users/SystemAdmin"),
        meta: {
          title: "Lima | System Admin",
          requiresAuth: false,
          isAdmin: true,
        },
      },
      {
        path: "/users/crop-scientist",
        name: "Crop Scientist",
        component: () => import("@/components/admin/users/CropScientist"),
        meta: {
          title: "Lima | Crop Scientist",
          requiresAuth: false,
          isAdmin: true,
        },
      },
    ],
  },
];
const indexRoutes = [];
const routes = indexRoutes.concat(
  childrenRoutes,
  profile,
  produce,
  producers,
  messaging
);
export default routes;
