const routes = [
  {
    path: "/messages",
    name: "Messages",
    component: () => import("@/components/admin/messages/Messages"),
    meta: {
      title: "Lima | Messages",
      requiresAuth: true,
      isAdmin: true,
    },
  },
];
export default routes;
