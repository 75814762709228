const state = {
  activeIndex: 0,
  authToken: "",
  tokenExpiry: "",
  activeUser: null,
  selectedProducer: null,
  businessDetails: null,
  categories: [],
  paymentPage: null,
  paymentUrl: null,
  selectedQuote: null,
};
export default state;
