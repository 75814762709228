import profile from "@/router/cropScientist/profile";
import products from "@/router/cropScientist/products";

const childrenRoutes = [
  {
    path: "/producers",
    name: "Suppliers",
    component: () => import("@/components/cropScientist/producers/Index"),
    meta: {
      title: "Lima | Suppliers",
      requiresAuth: true,
    },
  },
  {
    path: "/crop-scientist-dashboard",
    name: "CropScientist Dash",
    component: () => import("@/components/cropScientist/dashboard/Index"),
    meta: {
      title: "Lima | Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/producer",
    name: "View Producer",
    props: true,
    component: () => import("@/components/cropScientist/producers/Producer"),
    meta: {
      title: "Lima | Crop Suppliers",
      requiresAuth: true,
    },
    children: [
      {
        path: "/producer/crops",
        name: "Crops",
        component: () => import("@/components/cropScientist/producers/Crops"),
        meta: {
          title: "Lima | Producer Crops",
          requiresAuth: true,
        },
      },
      {
        path: "/producer/land-details",
        name: "Land Details",
        component: () =>
          import("@/components/cropScientist/producers/LandDetails"),
        meta: {
          title: "Lima | Producer Land Details",
          requiresAuth: true,
        },
      },
    ],
  },
];
const routes = childrenRoutes.concat(products, profile);
export default routes;
