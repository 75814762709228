import { createRouter, createWebHistory } from "vue-router";
import admin from "@/router/admin/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import cropScientist from "@/router/cropScientist";
import buyer from "@/router/buyer";
import store from "@/store";

const app = [
  {
    path: "/",
    name: "LandingPage",
    component: () => import("@/components/main/LandingPage"),
    redirect: "/browse-produce",
    meta: {
      title: "Lima | Home",
      requiresAuth: false,
    },
  },
  {
    path: "/payment-submitted",
    name: "Payment Submitted",
    component: () => import("@/components/buyer/quotes/SuccessfulPayment"),
    meta: {
      title: "Lima | Payment Submitted",
      requiresAuth: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("@/components/main/Contact"),
    meta: {
      title: "Lima | Contact Us",
      requiresAuth: false,
    },
  },
  {
    path: "/about-us",
    name: "About Us",
    component: () => import("@/components/main/AboutUs"),
    meta: {
      title: "Lima | About Us",
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/components/auth/Login"),
    meta: {
      title: "Lima | Login",
      requiresAuth: false,
    },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: () => import("@/components/auth/SetPassword"),
    meta: {
      title: "Lima | Password Reset",
      requiresAuth: false,
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/components/auth/PasswordReset"),
    meta: {
      title: "Lima | Password Reset",
      requiresAuth: false,
    },
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import("@/components/auth/NewPassword"),
    meta: {
      title: "Lima | Forgot Password",
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/components/auth/ForgotPassword"),
    meta: {
      title: "Lima | Forgot Password",
      requiresAuth: false,
    },
  },
  {
    path: "/email-sent",
    name: "EmailSent",
    component: () => import("@/components/auth/EmailSent"),
    meta: {
      title: "Lima | Forgot Password",
      requiresAuth: false,
    },
  },
  {
    path: "/buyer-register",
    name: "SignUp",
    component: () => import("@/components/auth/SignUp"),
    meta: {
      title: "Lima | Buyer Register",
      requiresAuth: false,
    },
  },
];
let routes;
routes = app.concat(admin, buyer, cropScientist);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  const loggedIn = store.state.activeUser;
  const isAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAdmin = to.matched.some((record) => record.meta.isAdmin);

  if (isAuth) {
    if (loggedIn === null) {
      router.push("/login");
    } else {
      if (isAdmin && store.state.activeUser != null) {
        if (
          store.state.activeUser.roles[0] !== "SYSTEM_ADMIN" &&
          store.state.activeUser.roles[0] !== "ADMIN"
        ) {
          router.push("/login");
        }
      }
      next();
    }
  } else {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
