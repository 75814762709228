const routes = [
  {
    path: "/buyer-quotes",
    name: "Buyer Quotes",
    component: () => import("@/components/admin/orders/Index"),
    meta: {
      title: "Lima | My Quotes",
      requiresAuth: true,
    },
    children: [
      {
        path: "/submitted-quotes",
        name: "Submitted Quotes",
        component: () => import("@/components/admin/orders/PendingQuotes"),
        meta: {
          title: "Lima | Pending Quotes",
          requiresAuth: true,
        },
      },
      {
        path: "/customer-orders",
        name: "Customer Orders",
        component: () => import("@/components/admin/orders/CustomerOrders"),
        meta: {
          title: "Lima | Customer Orders",
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/crop-produce",
    name: "Crop Produce",
    component: () => import("@/components/admin/products/Products"),
    meta: {
      title: "Lima | produce",
      requiresAuth: true,
    },
    children: [
      {
        path: "/produce/categories",
        name: "Categories",
        component: () => import("@/components/admin/products/Categories"),
        meta: {
          title: "Lima | Crop Categories",
          requiresAuth: true,
        },
      },
      {
        path: "/produce/crop-types",
        name: "Crop Types",
        component: () => import("@/components/admin/products/Index"),
        meta: {
          title: "Lima | Crops",
          requiresAuth: true,
        },
      },
      {
        path: "/produce/all-produce",
        name: "AllProducts",
        component: () => import("@/components/admin/products/AllCrops"),
        meta: {
          title: "Lima | Produce",
          requiresAuth: true,
        },
      },
    ],
  },
];
export default routes;
