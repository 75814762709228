const routes = [
  {
    path: "/manage-profile",
    name: "Profile",
    component: () => import("@/components/profile/Profile"),
    meta: {
      title: "Lima | Manage Profile",
      requiresAuth: true,
    },
  },
];
export default routes;
