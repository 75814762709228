import main from "@/router/cropScientist/farmer";

const routes = [
  {
    path: "/dashboard",
    name: "CropScientist Dashboard",
    component: () => import("@/components/cropScientist/layout/Dashboard"),
    meta: {
      title: "Lima | dashboard",
      requiresAuth: true,
    },
    children: main,
  },
];

export default routes;
