const routes = [
  {
    path: "/lima-producers",
    name: "Lima Suppliers",
    component: () => import("@/components/admin/producers/Index"),
    meta: {
      title: "Lima | Suppliers",
      requiresAuth: true,
      isAdmin: true,
    },
  },
  {
    path: "/lima-producer",
    name: "View Lima Producer",
    props: true,
    component: () => import("@/components/admin/producers/Producer"),
    meta: {
      title: "Lima | Crop Suppliers",
      requiresAuth: true,
      isAdmin: true,
    },
    children: [
      {
        path: "/lima-producer/crops",
        name: "Lima Crops",
        component: () => import("@/components/admin/producers/Crops"),
        meta: {
          title: "Lima | Producer Crops",
          requiresAuth: true,
          isAdmin: true,
        },
      },
      {
        path: "/lima-producer/land-details",
        name: "Lima Land Details",
        component: () => import("@/components/admin/producers/LandDetails"),
        meta: {
          title: "Lima | Producer Land Details",
          requiresAuth: true,
          isAdmin: true,
        },
      },
    ],
  },
];
export default routes;
