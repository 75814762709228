let mutations = {
  SET_ACTIVE_INDEX(state, data) {
    return (state.activeIndex = data);
  },
  SET_AUTH_TOKEN(state, data) {
    return (state.authToken = data);
  },
  SET_AUTH_TOKEN_EXPIRY(state, data) {
    return (state.tokenExpiry = data);
  },
  SET_AUTHENTICATED_USER(state, data) {
    return (state.activeUser = data);
  },
  SET_SELECTED_PRODUCER(state, data) {
    return (state.selectedProducer = data);
  },
  SET_BUYER_DETAILS(state, data) {
    return (state.businessDetails = data);
  },
  SET_CATEGORIES(state, data) {
    return (state.categories = data);
  },
  SET_PAYMENT_PAGE(state, data) {
    return (state.paymentPage = data);
  },
  SET_PAYMENT_URL(state, data) {
    return (state.paymentUrl = data);
  },
  SET_QUOTE(state, data) {
    return (state.selectedQuote = data);
  },
};
export default mutations;
