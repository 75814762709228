import main from "@/router/admin/users.js";

const routes = [
  {
    path: "/sys-admin-dashboard",
    name: "Admins Dashboard",
    component: () => import("@/components/admin/layout/Dashboard"),
    meta: {
      title: "Lima | dashboard",
      requiresAuth: true,
      isAdmin: true,
    },
    children: main,
  },
];

export default routes;
