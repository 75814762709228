import { createApp } from "vue";
import App from "@/App.vue";
import Notification from "@/utils/NotificationToast";
import $bus from "@/utils/EventBus";
import "@/index.css";
import "@/assets/styles/app.sass";
import VueApexCharts from "vue3-apexcharts";
import "v-calendar/dist/style.css";
import { DatePicker } from "v-calendar";
import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/genesis";
import "@/assets/tailwind.css";
import "@/scripts";
import router from "@/router";
import store from "@/store";
const app = createApp(App);
app.config.globalProperties.$bus = $bus;
app.config.globalProperties.$notification = new Notification();
app.use(VueApexCharts);
app.component("datepicker", DatePicker);
app.use(store).use(plugin, defaultConfig).use(router).mount("#app");
