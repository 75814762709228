let getters = {
  activeIndex: (state) => {
    return state.activeIndex;
  },
  activeUser: (state) => {
    return state.activeUser;
  },
  tokenExpiry: (state) => {
    return state.tokenExpiry;
  },
  authToken: (state) => {
    return state.authToken;
  },
  selectedProducer: (state) => {
    return state.selectedProducer;
  },
  businessDetails: (state) => {
    return state.businessDetails;
  },
  categories: (state) => {
    return state.categories;
  },
  paymentPage: (state) => {
    return state.paymentPage;
  },
  selectedQuote: (state) => {
    return state.selectedQuote;
  },
  paymentUrl: (state) => {
    return state.paymentUrl;
  },
};
export default getters;
