const routes = [
  {
    path: "/buyer-dashboard",
    name: "Buyer Dashboard",
    component: () => import("@/components/buyer/layout/Dashboard"),
    meta: {
      title: "Lima | dashboard",
    },
    children: [
      {
        path: "/my-profile",
        name: "My Profile",
        component: () => import("@/components/profile/Profile"),
        meta: {
          title: "Lima | Manage Profile",
          requiresAuth: true,
        },
      },
      {
        path: "/search",
        name: "Search",
        component: () => import("@/components/buyer/products/Search"),
        meta: {
          title: "Lima | Search",
          requiresAuth: false,
        },
      },
      {
        path: "/browse-produce",
        name: "Product Browser",
        component: () => import("@/components/buyer/products/Index"),
        meta: {
          title: "Lima | Produce Browser",
          requiresAuth: false,
        },
      },
      {
        path: "/browse-produce/:category",
        name: "Category Produce",
        component: () => import("@/components/buyer/products/CropsPerCategory"),
        meta: {
          title: "Lima | Produce Browser",
          requiresAuth: false,
        },
        children: [],
      },
      {
        path: "/make-payment",
        name: "Make payment",
        component: () => import("@/components/buyer/quotes/MakePayment"),
        meta: {
          title: "Lima | Make payment",
          requiresAuth: true,
        },
      },
      {
        path: "/my-quotes",
        name: "My Quotes",
        component: () => import("@/components/buyer/quotes/Index"),
        meta: {
          title: "Lima | My Quotes",
          requiresAuth: true,
        },
        children: [
          {
            path: "/pending-quotes",
            name: "Pending Quotes",
            component: () => import("@/components/buyer/quotes/PendingQuotes"),
            meta: {
              title: "Lima | Pending Quotes",
              requiresAuth: true,
            },
          },
          {
            path: "/my-orders",
            name: "My Orders",
            component: () => import("@/components/buyer/quotes/MyOrders"),
            meta: {
              title: "Lima | My Orders",
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
