const routes = [
  {
    path: "/produce",
    name: "Produce",
    component: () => import("@/components/cropScientist/products/Products"),
    meta: {
      title: "Lima | produce",
      requiresAuth: true,
    },
    children: [
      {
        path: "/products/crop-categories",
        name: "CropCategory",
        component: () =>
          import("@/components/cropScientist/products/Categories"),
        meta: {
          title: "Lima | Crop Categories",
          requiresAuth: true,
        },
      },
      {
        path: "/products/crop-list",
        name: "CropsList",
        component: () => import("@/components/cropScientist/products/Index"),
        meta: {
          title: "Lima | Crops",
          requiresAuth: true,
        },
      },
      {
        path: "/products/suppliable-produce",
        name: "AllCrops",
        component: () => import("@/components/cropScientist/products/AllCrops"),
        meta: {
          title: "Lima | Produce",
          requiresAuth: true,
        },
      },
    ],
  },
];
export default routes;
