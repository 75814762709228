<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style lang="sass">
#app
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: #f8fafc
  min-height: 100vh

#nprogress
  .bar
    background: #008743 !important
    height: 6px
  .spinner-icon
    border: none !important
    display: none !important
</style>
